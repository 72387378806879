<template>
  <div class="intro-container" v-loading="isLoading" 
       element-loading-text="Loading..."
       element-loading-background="rgba(255, 255, 255, 0.8)">
    
    <div class="ClassDetail">

      <img :src="class_cover"
            style="width: 180px;height: 180px;object-fit: cover;border-radius: 12px;  float: left;">

      <div class="nameClass">{{ class_name }}</div>

      <div class="introClass">{{ class_intro  }}</div>

    </div>

    <div style="clear: both;"></div>

    <div class="ClassList">      
      
      <el-table
        :data="tableData2"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @row-click="rowClik"
        empty-text="No Data"
        >
      
        <el-table-column
          label="Lesson"
          width="300"
          >

          <template slot-scope="scope">
            <div style="word-break:normal">
              {{   scope.row.name}}
            </div>
                    
          </template>

        </el-table-column>
        <el-table-column
          label="Description"
          >

          <template slot-scope="scope">
            <div style="word-break:normal">
              {{   scope.row.session_description}}
            </div>
                    
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="sessionLen"
          label="Course hours">
        </el-table-column> -->
        <el-table-column
          prop="sessionNum"
          label="Content"
          width="120"
          >
        </el-table-column>
        
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  createApplication,
  checkApplicationNew,
  getSessions
} from '../api/ipsye'
import {
  getUserId,
  isTestUser
} from '../utils/store'
export default {
  name: "Intro",
  data() {
    return {
      isLoading: false,
      accountPaid: false,
      tableData2: [],
      class_id: '666a9c5ba4640b08608b45a9',
      class_cover:null,
      class_name:'',
      class_intro:''
    }
  },
  mounted() {
    // if (isTestUser()) {
    //   checkApplicationNew(getUserId()).then((res) => {
    //     let result = res.data
    //     if (result.code == 0) {
    //       this.accountPaid = true
    //     } else {
    //       this.accountPaid = false
    //     }
    //     this.$forceUpdate()
    //   })
    // }

    this.loadData()
  },
  methods: {
    doPay() {
      let user_id = getUserId()
      createApplication(user_id).then((res) => {
        let result = res.data.data
        if (result.application_status == '无需缴费') {
          this.accountPaid = true
          this.$globalMessage.$emit('refreshApplication')
          this.$forceUpdate()
          return
        }
        this.$router.push({
          path: '/order',
          query: {
            application_id: result._id.$id
          }
        })
        this.showPayDialog = false
      })
    },

    loadData(){
      this.isLoading = true
      console.log(1111,this.class_id,getUserId())
      getSessions(this.class_id, getUserId()).then((data) => {
        console.log(data.data)
        this.class_cover = data.data.class_info.class_avatar_url
        this.class_name = data.data.class_info.class_name
        this.class_intro = data.data.class_info.class_description

        // this.session_data = data.data.sessions
        data.data.sessions.map((i,index) => {
          this.tableData2.push({
            name: i.session_info.session_name,
            sessionNum: i.session_first_content_count,
            sessionLen:parseInt(i.session_length/60)+"'"+(i.session_length%60)+'"',
            seession_id: i._id.$id,
            session_description: i.session_info.session_description,
            index,
          })
        })
        this.tableData2 = [...this.tableData2]
        console.log(this.tableData2)
      }).finally(() => {
        this.isLoading = false
      })
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    rowClik(row) {


      if (isTestUser()) {
        checkApplicationNew(getUserId()).then((res) => {
          let code = res.data.code
          if (code == 0) {
            this.$router.push({ path: '/content-home', query: { seession_id: row.seession_id }})

          } else {
            // this.$message.error('Please purchase ~')
            this.$globalMessage.$emit('showPayDialog')
          }
        })
        return
      }

      this.$router.push({ path: '/content-home', query: { seession_id: row.seession_id }})


      // let flag = true
      // if (row.index != 0 && this.required == 1){
      //   flag = (this.session_data[row.index-1].quiz_count == this.session_data[row.index-1].answer_count)
      // }
      // if(row.index == 0 || flag){
      //   this.$router.push({ path: '/content-home', query: { seession_id: row.seession_id }})

      // }else{
      //   this.$message.error('请完成上个session');
      // }

    },


  }
}
</script>

<style scoped lang="less">
.btn-purchase {
  margin-top: 15px;
  width: 85%;
  padding: 6px 12px;
  text-align: center;
  background-color: #D60034;
  font-size: 16px;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.intro-container {
  padding: 40px 60px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 90vh;
  background-color: #F8F8F8;
  font-size: 18px;
  font-weight: normal;
  color: #666666;
  word-break: keep-all;
  line-height: 32px;
}
.ClassList{
  margin-top: 20px;
  width: 100%;
  /* margin: 0 auto; */
}
.ClassDetail{
  width: 100%;
  float: left;

}
.nameClass{
  float: left;
  margin-left: 20px;
  margin-right: 20px;
  color: #333;
  font-size: 20px;
  width: calc(100% - 230px);
  // background-color: rebeccapurple;
}
.introClass{
  float: left;
  margin-left: 20px;
  margin-right: 20px;
  word-break: normal;
  font-size: 13px;
  width: calc(100% - 230px);
  margin-top: 10px;
  height: 120px;
  // background-color: rebeccapurple;
}
</style>
